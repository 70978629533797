/* Filter drawer at list page need such z-index for its parent root drawer */
@media (min-width: 1200px) { 
    .MuiDrawer-root {
        z-index: 1200;
    }
}

.summary-text p::before{
    content: ':'
  }
